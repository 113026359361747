import ScrollMagic from 'ScrollMagic';

export function animation() {

    let controller = new ScrollMagic.Controller();
    const sceneAdd = (element) => {
        let scene = new ScrollMagic.Scene({ triggerElement: element, triggerHook: .8, })
            .on('enter', () => {
                element.classList.add('action');
            })
            .addTo(controller);
    }

    //start
    const anime = document.querySelectorAll('.j-animation');
    const fadein = document.querySelectorAll('.j-fadein');
    const fadeleft = document.querySelectorAll('.j-fadeleft');
    const faderight = document.querySelectorAll('.j-faderight');
    const fadeup = document.querySelectorAll('.j-fadeup');
    const slide = document.querySelectorAll('.j-slide');
    const slideup = document.querySelectorAll('.j-slideup');

    Array.prototype.forEach.call(anime, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(fadein, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(fadeleft, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(faderight, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(fadeup, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(slide, (el, i) => {
        sceneAdd(el);
    });
    Array.prototype.forEach.call(slideup, (el, i) => {
        sceneAdd(el);
    });

}