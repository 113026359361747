export function header() {

    const path = location.pathname;

    const header = document.querySelector('.j-header');
    const header_btn = document.querySelector('.j-header_btn');
    const header_links = document.querySelectorAll('.j-header_link');

    header_btn.addEventListener('click', () => {
        if (header.classList.contains('active')) {
            header.classList.remove('active');
        } else {
            header.classList.add('active');
        }
    });

    //スクロールで非表示
    // const hH = header.clientHeight;
    // const winH = window.innerHeight;
    // const docH = document.documentElement.scrollHeight;
    // const windBtm = docH - winH;
    // // 現在地を示す変数を定義
    // let pos = 0;
    // // スクロール直前の位置を示す変数を定義
    // let lastPos = 0;

    // const onScroll = () => {
    //     if (pos > hH && pos > lastPos) {
    //         header.classList.add("header--unpinned");
    //     }
    //     // スクロール位置がヘッダーの高さ分より小さいか
    //     // またはスクロール位置が最後のスクロール位置より小さい場合はclass名を削除
    //     if (pos < hH || pos < lastPos || windBtm <= pos) {
    //         header.classList.remove("header--unpinned");
    //     }

    //     // 最後のスクロール位置を保存
    //     lastPos = pos;
    // };

    // window.addEventListener("scroll", () => {
    //     // スクロールするごとにpos（現在地）の値を更新
    //     pos = window.scrollY;
    //     onScroll();
    // });


    //ページごとにヘッダーにクラス付与
    // [...header_links].forEach((link) => {
    //     if (link.dataset.page == path) {
    //         link.classList.add("is-here")
    //     }
    // });

}